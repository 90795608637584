import { useCallback, useRef, useState } from "react";
import axios from "axios";

export default function useLiveSearch(isDetailPage) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const cancelRef = useRef(null);

  const search = useCallback(
    async (searchQuery) => {
      setLoading(true);
      cancelRef.current?.cancel();
      cancelRef.current = axios.CancelToken.source();
      try {
        const { data: lsData } = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/livesearch`, {
          params: { search: searchQuery, suggest: isDetailPage ? true : undefined },
          cancelToken: cancelRef.current.token,
        });
        setData(lsData);
      } catch (_) {
        //
      } finally {
        setLoading(false);
      }
    },
    [isDetailPage]
  );

  return { data, loading, search };
}
