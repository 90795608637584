import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Router from "next/router";
import clsx from "clsx";
import IconArrowRight from "@/common/icons/arrow-right.svg";
import IconChevronRight from "@/common/icons/chevron-right.svg";

const SearchBarBlogCard = ({ blog, closeRoutingOnBlur, responsive }) => {
  const { t } = useTranslation();

  return (
    <div
      className="mt-6 flex cursor-pointer items-center rounded-lg bg-white p-1 hover:bg-grey-100"
      role="presentation"
      onMouseDown={() => {
        closeRoutingOnBlur();
        Router.push(`/article/${blog.slug}`);
      }}
    >
      <div
        className={clsx(
          "relative h-16 w-16 shrink-0 overflow-hidden rounded-full border-4 border-white",
          responsive && "lg:h-20 lg:w-20"
        )}
      >
        <Image src={blog.picture} layout="fill" alt={blog.title} objectFit="cover" />
      </div>
      <div className="ml-8 mr-16 grow">
        <div className={clsx("font-semibold", responsive && "lg:text-xl")}>{blog.title}</div>
        <p className={clsx("text-xs leading-5 text-grey-700 line-clamp-1", responsive && "lg:line-clamp-2")}>
          {blog.description}
        </p>
      </div>
      <button
        type="button"
        className={clsx(
          "button-text hidden space-x-2 p-2 text-green hover:text-green-900",
          responsive && "lg:flex-center"
        )}
      >
        <span>{t`common:read-more`}</span>
        <IconArrowRight />
      </button>
      <button
        className={clsx("button-icon shrink-0", responsive && "lg:hidden")}
        type="button"
        aria-label={t`common:read-more`}
      >
        <IconChevronRight className="h-4 w-4 lg:h-8 lg:w-8" />
      </button>
    </div>
  );
};

export default SearchBarBlogCard;
