import clsx from "clsx";

const TYPE = {
  "grey-bordered": "font-semibold text-grey bg-purple-900 border border-grey",
  grey: "text-grey-800 bg-purple-800 cursor-default",
};

const Badge = ({ children, color = "grey-bordered", big = true, noWrap = false, className = null }) => (
  <span
    className={clsx(
      "rounded py-1 px-3 text-xs",
      TYPE[color],
      big && "lg:text-base",
      noWrap && "whitespace-nowrap",
      className && className
    )}
  >
    {children}
  </span>
);

export default Badge;
