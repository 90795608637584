import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import Avatar from "@/components/common/Avatar";
import UserState from "@/common/models/UserState";
import Badge from "@/components/common/Badge";
import IconArrowRight from "@/common/icons/arrow-right.svg";
import IconChevronRight from "@/common/icons/chevron-right.svg";
import IconState from "@/common/icons/online-state.svg";

const SearchBarConsultantCard = ({ consultant, closeRoutingOnBlur, responsive }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div
      className="mt-6 flex cursor-pointer items-center justify-between gap-2 rounded-lg bg-white hover:bg-grey-100 lg:p-1"
      role="presentation"
      onMouseDown={() => {
        closeRoutingOnBlur();
        router.push(`/${consultant.slug}`);
      }}
    >
      <div className={clsx("flex w-full items-center gap-3", responsive && "lg:w-1/3 lg:gap-6")}>
        <div className="relative">
          <Avatar
            image={consultant.picture}
            author={consultant.name}
            className={clsx("h-16 w-16 shrink-0 border-4 border-white", responsive && "lg:h-20 lg:w-20")}
          />
          <IconState
            className={clsx(
              "absolute bottom-0 left-1 w-6",
              responsive && "lg:bottom-1 lg:left-2 lg:w-8",
              UserState[consultant.state].textColor
            )}
          />
        </div>
        <div className="w-full min-w-0">
          <div className={clsx("font-semibold uppercase", responsive && "lg:text-xl")}>{consultant.name}</div>
          <div className="mt-3 flex h-6 flex-wrap items-center gap-3 overflow-hidden">
            {consultant.qualifications?.map((qualification) => (
              <Badge color="grey" key={qualification} noWrap big={false}>
                {qualification}
              </Badge>
            ))}
          </div>
        </div>
      </div>
      <p className={clsx("hidden w-1/2 text-xs leading-5 text-grey-700", responsive && "lg:line-clamp-2")}>
        {consultant.introduction}
      </p>
      <button
        type="button"
        className={clsx(
          "button-text hidden space-x-2 p-2 text-green hover:text-green-900",
          responsive && "lg:flex-center"
        )}
      >
        <span>{t`common:consultant-card.to-the-profile`}</span>
        <IconArrowRight />
      </button>
      <button
        className={clsx("button-icon shrink-0", responsive && "lg:hidden")}
        type="button"
        aria-label={t`common:consultant-card.to-the-profile`}
      >
        {/* lg'ler responsive'e giremedi button-icon yüzünden */}
        <IconChevronRight className="h-4 w-4 lg:h-8 lg:w-8" />
      </button>
    </div>
  );
};

export default SearchBarConsultantCard;
